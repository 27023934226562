import { DOMReady } from './lib/domready'

DOMReady(main)

export function main() {
  const toggles = document.querySelectorAll('[data-display-toggle]')
  for (const toggle of toggles) {
    toggle.addEventListener('change', function (e) {
      if (undefined === e.target.value) {
        return
      }

      const onCheck = document.querySelectorAll(toggle.dataset.displayChecked)
      const onUncheck = document.querySelectorAll(
        toggle.dataset.displayUnchecked
      )
      if (onCheck.length > 0) {
        ;[].forEach.call(onCheck, (el) => {
          if (el !== this) {
            if (toggle.querySelectorAll('input[type=radio]').length > 0) {
              if (
                e.target.value === '1' ||
                e.target.value ===
                  toggle.querySelectorAll('input[type=radio]')[1].value
              ) {
                el.classList.remove('hidden')
              } else {
                el.classList.add('hidden')
              }
            } else if (toggle.querySelectorAll('option').length > 0) {
              if (e.target.value === '0') {
                el.classList.remove('hidden')
              } else {
                el.classList.add('hidden')
              }
            } else {
              if (e.target.dataset.displayToggleInversed === void 0) {
                if (e.target.dataset.displayToggleRequiredChild !== void 0) {
                  if (e.target.checked) {
                    el.querySelectorAll('input').forEach(
                      (input) => (input.required = true)
                    )
                  } else {
                    el.querySelectorAll('input').forEach(
                      (input) => (input.required = false)
                    )
                  }
                }
                if (e.target.checked) {
                  el.classList.remove('hidden')
                } else {
                  el.classList.add('hidden')
                }
              } else {
                if (
                  typeof e.target.dataset.displayToggleRequiredChild !==
                  'undefined'
                ) {
                  if (!e.target.checked) {
                    el.querySelectorAll('input').forEach(
                      (input) => (input.required = true)
                    )
                  } else {
                    el.querySelectorAll('input').forEach(
                      (input) => (input.required = false)
                    )
                  }
                }
                if (!e.target.checked) {
                  el.classList.remove('hidden')
                } else {
                  el.classList.add('hidden')
                }
              }
            }
          }
        })
      }

      if (onUncheck.length > 0) {
        ;[].forEach.call(onUncheck, (el) => {
          if (el !== this) {
            if (toggle.querySelectorAll('input[type=radio]').length > 0) {
              if (
                e.target.value === '0' ||
                e.target.value ===
                  toggle.querySelectorAll('input[type=radio]')[0].value
              ) {
                el.classList.remove('hidden')
              } else {
                el.classList.add('hidden')
              }
            } else if (toggle.querySelectorAll('option').length > 0) {
              if (e.target.value === '1') {
                el.classList.remove('hidden')
              } else {
                el.classList.add('hidden')
              }
            } else {
              if (
                typeof e.target.dataset.displayToggleInversed === 'undefined'
              ) {
                if (e.target.checked) {
                  el.classList.add('hidden')
                } else {
                  el.classList.remove('hidden')
                }
              } else {
                if (!e.target.checked) {
                  el.classList.add('hidden')
                } else {
                  el.classList.remove('hidden')
                }
              }
            }
          }
        })
      }
    })
    toggle.dispatchEvent(new InputEvent('change'))
  }
}
