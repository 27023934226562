/**
 * Debounces a function, making it execute only once every {delay}
 * seconds. Takes the function to debounce as param, and returns a
 * debounced function.
 *
 * Useful for callbacks attached on input or scroll events for
 * instance.
 *
 * @example Example use
 * // executes the callback at most once every 2 seconds
 * element.addEventListener("input", debounce(callback, 2000))
 *
 * @param {function} callback The function to debounce
 * @param {number} delay The debounce delay in milliseconds
 * @param {function} [waitCallback]
 * @returns {function} The debounced function
 */
export function debounce(callback, delay, waitCallback) {
  let timeoutId = null
  return function () {
    const context = this
    const args = arguments
    clearTimeout(timeoutId)
    if (waitCallback) waitCallback.apply(context, args)
    timeoutId = setTimeout(function () {
      timeoutId = null
      callback.apply(context, args)
    }, delay)
  }
}

/**
 * Finds the first parent that responds to {predicate}. Returns NULL
 * if none found.
 *
 * @param node
 * @param predicate
 */
export function findParent(node, predicate) {
  let parent = node
  while ((parent = parent.parentNode)) {
    if (predicate(parent)) {
      return parent
    }
  }

  return null
}

/**
 * Decodes html entities in a string
 */
export function decodeHtmlEntities(string) {
  var txt = document.createElement("textarea");
  txt.innerHTML = string;

  return txt.value;
}