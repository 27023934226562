import { DOMReady } from './lib/domready'

DOMReady(main)

function handleToggle(e) {
  const wrappersCheck = document.querySelectorAll(
    e.target.dataset.displayChecked
  )
  for (const wrapper of wrappersCheck) {
    const inputs = wrapper.querySelectorAll('[data-required-toggle]')
    for (const input of inputs) {
      if (e.target.nodeName === 'SELECT') {
        input.required = e.target.value === '0'
      } else {
        input.required = e.target.checked
      }
    }
  }

  const wrappersUncheck = document.querySelectorAll(
    e.target.dataset.displayUnchecked
  )
  for (const wrapper of wrappersUncheck) {
    const inputs = wrapper.querySelectorAll('[data-required-toggle]')
    for (const input of inputs) {
      if (e.target.nodeName === 'SELECT') {
        input.required = e.target.value === '1'
      } else {
        input.required = !e.target.checked
      }
    }
  }

  if (e.target.dataset.displayChecked === void 0 && e.target.type === 'radio') {
    const selectsBox = document.querySelectorAll(
      '#' + e.target.id.substr(0, e.target.id.length - 2)
    )
    for (const selectBox of selectsBox) {
      const wrappers = document.querySelectorAll(
        selectBox.dataset.displayChecked
      )
      for (const wrapper of wrappers) {
        const inputs = wrapper.querySelectorAll('[data-required-toggle]')
        for (const input of inputs) {
          input.required = !!e.target.dataset.selectedRequired
        }
      }
    }
  }
}

export function main() {
  const toggles = document.querySelectorAll('[data-toggle-input]')
  for (const toggle of toggles) {
    toggle.addEventListener('change', handleToggle)
    const wrappers = document.querySelectorAll(toggle.dataset.displayChecked)
    for (const wrapper of wrappers) {
      const inputs = wrapper.querySelectorAll(':required')
      for (const input of inputs) {
        input.dataset.requiredToggle = true
        input.required = toggle.checked
      }
    }
  }

  const forms = document.getElementsByTagName('form')
  let shouldSubmit
  for (const form of forms) {
    const fields = form.querySelectorAll('input, textarea, select')
    let formTimeout

    shouldSubmit = true
    for (const field of fields) {
      field.addEventListener('invalid', (eArg) => {
        let isHidden = !(
          eArg.target.offsetWidth ||
          eArg.target.offsetHeight ||
          eArg.target.getClientRects().length
        )
        if (isHidden) {
          eArg.stopPropagation()
          eArg.preventDefault()
          eArg.stopImmediatePropagation()
          eArg.target.disabled = true
          form.formNoValidate = true
          formTimeout = setTimeout(() => {
            if (form.checkValidity()) {
              if (typeof SubmitEvent !== 'undefined') {
                form.dispatchEvent(new SubmitEvent('submit'))
              } else {
                form.submit()
              }
            }
          }, 100)
          return true
        }
      })
    }
  }
}
