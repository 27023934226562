import { findParent } from './util'
import { DOMReady } from './lib/domready'

DOMReady(main)

import { main as attachDisplayToggles } from './displaytoggles'
import { main as attachAutocomplete } from './addressable'

export function addItem(target) {
  const prototype = target.dataset.prototype
  if (!prototype) {
    return
  }
  const indexRaw = target.dataset.index
  const index = indexRaw === undefined ? 1 : Number(indexRaw) + 1
  const wrapper = document.createElement('div')
  wrapper.innerHTML = prototype
    .replace(/__name__label__/g, target.dataset.label + ' #' + index)
    .replace(/__name__/g, index)
  const item = wrapper.firstElementChild
  target.appendChild(item)
  target.dataset.index = String(index)

  attachDisplayToggles()
  attachAutocomplete()
  attachInternalRemovers()

  return item
}

export function removeItem(target) {
  const indexRaw = target.dataset.index
  const index = indexRaw === undefined ? 0 : Number(indexRaw)
  if (index <= 0) {
    return
  }
  target.removeChild(target.children[index - 1])
  target.dataset.index = String(index - 1)
}

export function removeInternalItem(target) {
  const indexRaw = target.dataset.index
  const index = indexRaw === undefined ? 0 : Number(indexRaw)
  if (index <= 0) {
    return
  }
  target.dataset.index = String(index - 1)
}

export function setItem(target, desiredIndex) {
  const indexRaw = target.dataset.index
  const index = indexRaw === undefined ? 0 : Number(target.dataset.index)

  // case when desired index and current index are the same: we do nothing and quit early
  if (desiredIndex === index) {
    return
  }

  // case when desired < current: we remove children
  if (desiredIndex < index) {
    for (let i = 0; i < index - desiredIndex; i = i + 1) {
      removeItem(target)
    }
  }

  // case when desired > current: we append children
  if (desiredIndex > index) {
    for (let i = 0; i < desiredIndex - index; i = i + 1) {
      addItem(target)
    }
  }
}

function attachAdders() {
  const adders = document.querySelectorAll('[data-prototype-adder]')
  for (const adder of adders) {
    adder.addEventListener('click', function (e) {
      if (!e.target.dataset.bsTarget) {
        return
      }
      const target = document.querySelector(e.target.dataset.bsTarget)
      if (!target) {
        return
      }
      addItem(target)
    })
  }
}

function attachRemovers() {
  const removers = document.querySelectorAll('[data-prototype-remover]')
  for (const remover of removers) {
    remover.addEventListener('click', function (e) {
      if (!e.target.dataset.bsTarget) {
        return
      }
      const target = document.querySelector(e.target.dataset.bsTarget)
      if (!target) {
        return
      }
      removeItem(target)
    })
  }
}

function attachInternalRemovers() {
  const internalRemovers = document.querySelectorAll(
    '[data-prototype-internal-remover]'
  )
  for (const internalRemover of internalRemovers) {
    internalRemover.addEventListener('click', function (e) {
      // very flimsy check for finding the form container
      // todo: update for something less flimsy
      const container = findParent(
        internalRemover,
        (node) => node.parentNode && !!node.parentNode.dataset.prototype
      )
      if (!container) {
        return
      }

      const target = findParent(
        internalRemover,
        (node) => !!node.dataset.prototype
      )
      if (!target) {
        return
      }
      removeInternalItem(target)
      container.parentNode.removeChild(container)
    })
  }
}

function attachSetters() {
  const setters = document.querySelectorAll('[data-prototype-setter]')
  for (const setter of setters) {
    const targetSelector = setter.dataset.bsTarget
    if (!targetSelector) {
      return
    }
    const target = document.querySelector(targetSelector)
    if (!target) {
      return
    }
    setter.addEventListener('change', function (e) {
      setItem(target, Number(e.target.value))
    })
  }
}

function main() {
  attachAdders()
  attachRemovers()
  attachInternalRemovers()
  attachSetters()
}
